/**
 * Created by slava on 4/12/17.
 */

cleverapps.RestClient = {
    METHODS: {
        GET: "GET",
        POST: "POST"
    },

    getDefaultTimeout: function () {
        return connector.platform.oneOf(connector.WECHAT) ? 20000 : 10000;
    },

    getXMLHttpRequest: function () {
        return typeof XMLHttpRequest !== "undefined" ? new XMLHttpRequest() : new ActiveXObject("MSXML2.XMLHTTP");
    },

    get: function (path, data, onSuccess, onError, options) {
        this.send(this.METHODS.GET, path, data, onSuccess, onError, options);
    },

    post: function (path, data, onSuccess, onError, options) {
        this.send(this.METHODS.POST, path, data, onSuccess, onError, options);
    },

    send: function (httpMethod, path, data, onSuccess, onError, options) {
        options = options || {};

        if (cleverapps.flags && cleverapps.flags.norest && (!options.ignoreNoRest || cleverapps.config.norest)) {
            var msg = "Rest is not available for this platform";
            cleverapps.throwAsync(msg);
            onError && onError({ errorMessage: msg });
            return;
        }

        data = data || {};
        var timeout = options.timeout || this.getDefaultTimeout();
        var fullPath = path.indexOf("http") !== 0 && path.indexOf("/.proxy/") !== 0 && !options.fullPath ? cleverapps.config.restApiURL + path : path;
        var reqListener;

        var oReq = this.getXMLHttpRequest();

        var errorTimeout = setTimeout(function () {
            reqListener.call({
                status: -1,
                responseText: "our timeout"
            });
        }, timeout + 1000);

        reqListener = function () {
            if (errorTimeout !== undefined) {
                clearTimeout(errorTimeout);
                errorTimeout = undefined;
            }
            if (this.status >= 200 && this.status < 300) {
                if (onSuccess) {
                    var success = true;
                    var json;

                    try {
                        json = this.responseText && JSON.parse(this.responseText);
                    } catch (e) {
                        success = false;
                        cleverapps.throwAsync("parse json error path - " + path + " error - " + e.message + " text - " + this.responseText);
                    }

                    if (success) {
                        onSuccess(json);
                    } else if (onError) {
                        onError(this);
                    }
                }
            } else {
                if (this.responseText) {
                    this.errorMessage = (this.responseText.split("\n")[0] || "").trim();
                }

                console.log("XMLHttpRequest FAILED", httpMethod, this.status, this.errorMessage, fullPath);

                if (onError) {
                    onError(this);
                }
            }

            onSuccess = function () {};
            onError = function () {};
        }.bind(oReq);

        oReq.ontimeout = reqListener;
        oReq.onload = reqListener;
        oReq.onerror = reqListener;
        oReq.onabort = reqListener;

        oReq.open(httpMethod, fullPath);
        oReq.timeout = timeout;

        oReq.setRequestHeader("Content-Type", "application/json");

        if (options.authorization) {
            oReq.setRequestHeader("authorization", options.authorization);
        } else {
            oReq.setRequestHeader("Cache-Control", "no-cache");
        }

        oReq.send(JSON.stringify(data));
    },

    sendRaw: function (httpMethod, path, rawData) {
        var oReq = this.getXMLHttpRequest();

        oReq.open(httpMethod, path);
        oReq.timeout = this.getDefaultTimeout();

        oReq.send(rawData);
    },

    standsWeb: function (url) {
        url = url || "";
        return cleverapps.config.deployment + url;
    },

    standsRest: function (url) {
        url = url || "";
        return cleverapps.config.deployment + "/" + cleverapps.config.name + "-rest" + url;
    }
};
